import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import FieldSelect from "../../../components/FieldSelect/FieldSelect";
import PdfViewer, { PdfFile } from "../../../components/PdfViewer/PdfViewer";
import { SelectOption } from "../../../interface";
import { TOOLTIP_SECTIONS } from "../../../enums";
import style from "./PreviewPackage.module.scss";
import TooltipWrapper from "../../../components/TooltipWrapper/TooltipWrapper";
import {
  downloadFiledReturnPackage,
  downloadImage,
  getErrorMessage,
  getReturnCustomerForm,
  getReturnFormAttachment,
  markAssetAsFilled,
} from "../../../services";
import { exportPdf, responseToFile } from "../../../utils/exportFile";
import _ from 'lodash';
import Loader from '../../../components/Loader/Loader';
import ToastMessage, { ToastMessageRefType } from '../../../components/ToastMessage/ToastMessage';

function PreviewPackage() {
  const navigate = useNavigate();
  const params = useParams();
  const [pdfFiles, setPdfFiles] = useState([] as PdfFile[]);
  const formId = useRef<number>();
  const [confirming, setConfirming] = useState(false);
  const [fetchingReturnCustomerForm, setFetchingReturnCustomerForm] = useState(false);
  const toastRef = useRef<ToastMessageRefType | null>(null);

  useEffect(() => {
    if (params.returnId) {
      setFetchingReturnCustomerForm(true);
      getReturnCustomerForm(Number(params.returnId)).then((res) => {
        formId.current = res.data.id;

        const files: PdfFile[] = [];
        const promises: Promise<any>[] = [];
        res.data.customerFormAttachments.forEach((entry, index) => {
          files.push({ name: entry.formAttachment.name } as PdfFile);
          promises.push(getReturnFormAttachment(Number(params.returnId), entry.id).then((x) => 
            files[index].source = x.data
          ));
          promises.push(downloadImage(entry.formAttachment.thumbnailDocumentId).then((x) =>
            files[index].thumbnail = responseToFile(x)
          ));
        })
        Promise.all(promises).then(() => {
          setPdfFiles(files);
          setFetchingReturnCustomerForm(false);
        }).catch(() => {
          setFetchingReturnCustomerForm(false);
        });
      }).catch(() => {
        setFetchingReturnCustomerForm(false);
      });
    }
  }, [params.returnId]);

  const breadcrumb = [
    {
      value: "Returns",
      href: "/returns?tab=File+Packages",
    },
    {
      value: "Manage Returns",
      href: "/returns?tab=File+Packages",
    },
    { value: "Preview Package" },
  ];

  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  const [selectedMode, setSelectedMode] = useState<number>(1);
  const modeOptions = useMemo<SelectOption[]>(() => {
    return [
      {
        value: 1,
        label: "Edit package",
        name: "Edit package",
      },
      {
        value: 2,
        label: "Account Package Contents",
        name: "Account Package Contents",
      },
      {
        value: 3,
        label: "Global Package Contents",
        name: "Global Package Contents",
      },
    ];
  }, []);

  const clickDownloadAndMarkAsFiled = () => {
    setConfirmationOpen(true);
  };

  const clickBack = () => {
    navigate("/returns?tab=File+Packages");
  };

  return (
    <div className={style["page"]}>
      <div className={style["main-content"]}>
        <div className={style["main-content-top"]}>
          {/* Braedcrumb */}
          <Breadcrumb items={breadcrumb} />
          <ToastMessage
            ref={toastRef}
            className={style['toast']}
          />
          <div className={style["page-header"]}>
            <h2>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageTitle}
                tooltipKey="Preview Package"
              >
                <span>Preview Package</span>
              </TooltipWrapper>
            </h2>
            <div className={style["page-header-actions"]}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey="Download and mark as filed"
              >
                <button
                  type="button"
                  className="primary"
                  onClick={clickDownloadAndMarkAsFiled}
                >
                  Download and mark as filed
                </button>
              </TooltipWrapper>
              <FieldSelect
                classnames={style["mode-select"]}
                labelText=""
                options={modeOptions}
                selectId={selectedMode}
                onSelect={(selectedOption) => {
                  const selectedValue = _.toNumber(selectedOption?.value);
                  if (selectedValue !== selectedMode) {
                    setSelectedMode(selectedValue as number);
                    if (selectedValue === 2) {
                      navigate(
                        `/forms/packageContents/${formId.current}/return/${params.returnId}`,
                        {
                          state: { backTrack: true },
                        }
                      );
                    } else if (selectedValue === 3) {
                      navigate(`/forms/packageContents/${formId.current}`, {
                        state: { backTrack: true },
                      });
                    }
                  }
                }}
                force
              />
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey="Back"
              >
                <button
                  type="button"
                  className="primary"
                  onClick={clickBack}
                >
                  Back
                </button>
              </TooltipWrapper>
            </div>
          </div>
        </div>
        <div className={style["page-content"]}>
          {pdfFiles && pdfFiles.length > 0 && <PdfViewer className={style["pdf-viewer"]} files={pdfFiles} />}
        </div>
      </div>
      <ConfirmModal
        isOpen={confirmationOpen}
        onRequestClose={() => setConfirmationOpen(false)}
        title="Confirmation"
        content="Are you sure you want to mark the return as filed? Remember, this action is irreversible."
        confirming={confirming}
        onConfirm={() => {
          setConfirming(true);
          markAssetAsFilled(Number(params.returnId))
            .then((res) => {
              const errorMessage = getErrorMessage(res);
              if (errorMessage) {
                toastRef.current?.showErrorToast(errorMessage);
              } else {
                toastRef.current?.showSuccessToast('Update successfully');
                return downloadFiledReturnPackage(Number(params.returnId));
              }
            })
            .then((target) => {
              if (target) {
                return exportPdf(target, _.noop);
              }
            })
            .then(() => {
              setConfirmationOpen(false);
              setConfirming(false);
            }).catch((e) => {
              toastRef.current?.showErrorToast(getErrorMessage(e));
              setConfirming(false);
            });
        }}
      />

      <Loader isOpen={fetchingReturnCustomerForm} />
    </div>
  );
}

export default PreviewPackage;
