import React, {useState, useRef, useMemo, useEffect} from 'react';
import _, {
  Dictionary,
  last,
  keys,
  values,
  keyBy,
  kebabCase,
  orderBy,
  compact,
  Function1,
  map,
  assign,
  isEmpty,
  toString,
  some,
  camelCase,
} from 'lodash';
import InitTable, {Query} from '../Table/Table';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import {
  Assessor,
  PurchasedReturnYear,
  ReturnsItem,
  State,
} from '../../interface';
import {
  approveBulkAsset,
  downloadFiledReturnPackage,
  getAssessorsByAccount,
  getErrorMessage,
  getReturns,
  getUserProfile,
  markAssetAsFilled,
  unapproveAsset,
  bulkMarkReturnAsFiled,
  bulkDownloadFiledReturnPackage,
  unfileReturn
} from '../../services';
import {TOOLTIP_SECTIONS} from '../../enums';
import style from './ApproveAssets.module.scss';
import {updateLoadingObjectByIds} from '../../utils/reactState';
import {ToastMessageRefType} from '../ToastMessage/ToastMessage';
import {useNavigate} from 'react-router-dom';
import Loader, {LoaderIndicator} from '../Loader/Loader';
import classNames from 'classnames';
import {InputType} from '../Table/components/Editor/Editor';
import {formatDate} from '../../utils/stringUtil';
import TableMenu, {MenuItem} from '../TableMenu/TableMenu';
import {exportPdf} from '../../utils/exportFile';
import useUserProfile from '../../hook/useUserProfile';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import BaseModal from '../BaseModal/BaseModal';
import useSelectedSystemTaxYear from '../../hook/useSelectedSystemTaxYear';
import FieldToggleCheckbox from '../FieldToggleCheckbox/FieldToggleCheckbox';
import moment from 'moment';

const {Table, Column, TopBar} = InitTable<ReturnsItem>();

interface Props {
  states: State[];
  type: string;
  toastRef: React.MutableRefObject<ToastMessageRefType | null>;
  deadline: string;
  includeReturnsWithNoAssets: boolean;
}

interface PurchasedReturnYearExt extends PurchasedReturnYear {
  returnsUsed: number;
}

enum RETURNSTYPE {
  ApproveAssets = 'Approve Assets',
  PreviewForms = 'Preview Forms',
  FilePackages = 'File Packages'
}

export default function ApproveAssets(props: Props) {
  const {states, type, toastRef, deadline, includeReturnsWithNoAssets} = props;
  const navigate = useNavigate();
  const userProfile = useUserProfile();
  const accountId = userProfile?.userAccountId;
  const isFreeTrial = userProfile?.isFreeTrial;

  // const RETURNSTYPE = ['Approve Assets', 'Preview Forms', 'File Packages'];
  const [items, setItems] = useState<ReturnsItem[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selected, setSelected] = useState<ReturnsItem[]>([]);
  const [query, setQuery] = useState<Query>({filter: {status: getStatuses(type)}});
  const [downloading, setDownloading] = useState<{[key: number]: boolean}>({});
  const [approving, setApproving] = useState<{[key: number]: boolean}>({});
  const [approvingSingleAsset, setApprovingSingleAsset] = useState<boolean>(false);
  const [unapproving, setUnapproving] = useState<{[key: number]: boolean}>({});
  const [unfiling, setUnfiling] = useState<{[key: number]: boolean}>({});
  const [assessors, setAssessors] = useState([]);
  const [confirmId, setConfirmId] = useState<number | null>(null);
  const [fetchingAllAssessors, setFetchingAllAssessors] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [confirmingUnfile, setConfirmingUnfile] = useState(false);
  const [bulkFiling, setBulkFiling] = useState(false);
  const selectedSystemTaxYear = useSelectedSystemTaxYear();

  const lastQueryRef = useRef<Dictionary<unknown>>();
  const requestCancelRef = useRef<AbortController | null>(null);

  useEffect(() => {
    if (accountId) {
      setFetchingAllAssessors(true);
      getAssessorsByAccount({userAccountId: toString(accountId)})
        .then((d) => {
          setAssessors(
            d.data.items.map((item: Assessor) => ({
              id: item.id,
              label: item.name,
              name: item.name,
              value: item.id,
            })),
          );
          setFetchingAllAssessors(false);
        })
        .catch(() => {
          setFetchingAllAssessors(false);
        });
    }
  }, [accountId]);

  const idsSelectedReviewAssets = useMemo(() => {
    const selectedReviewAssets = _.filter(selected, {status: 'Review Assets'});
    return selectedReviewAssets.map((item) => item.id);
  }, [selected]);

  const idsSelectedApproved = useMemo(() => {
    const selectedApprovedAssets = _.filter(selected, {status: 'Approved'});
    return selectedApprovedAssets.map((item) => item.id);
  }, [selected]);

  function setDownloadId(id: number, flag: boolean) {
    setDownloading({
      ...downloading,
      [id]: flag,
    });
  }

  const canApproveSelected = useMemo(
    () =>
      selected.length > 0 &&
      _.every(selected, (x) => x?.status === 'Review Assets'),
    [selected],
  );

  const canMarkAsFiledSelected = useMemo(
    () => {
      return selected.length > 0
        && selected.length <= 10
        && _.every(selected, x => x?.status === 'Approved'); 
    },
    [selected]
  );

  useEffect(() => {
    if (approvingSingleAsset) {
      bulkApproveAssets();
      setApprovingSingleAsset(false);
    }
  }, [approvingSingleAsset])

  const updateApprovingAsset = (ids: number[], deleting: boolean) => {
    updateLoadingObjectByIds(ids, deleting, approving, setApproving);
  };

  function approveBar() {
    if (isApprovingAsset(idsSelectedReviewAssets)) {
      return <LoaderIndicator className='button-loading' loading={true} />;
    } else {
      return null;
    }
  }

  function getStatuses(type: string) {
    switch (type) {
      case RETURNSTYPE.PreviewForms:
        return ['Approved'];
      case RETURNSTYPE.FilePackages:
        return ['Approved', 'Filed'];
      default:
        return ['Mapping Needed', 'Review Assets'];
    }
  }

  const isApprovingAsset = (ids: number[]) => {
    return ids.length > 0 && _.every(ids, (id) => !!approving[id]);
  };

  const stateOptions = useMemo(
    () =>
      orderBy(
        states?.map(({id, abbreviation}) => ({label: abbreviation, value: id})),
        (x) => x.label,
      ),
    [states],
  );

  function formatNameAndNumber(name?: string, number?: string) {
    return compact([name, number && `${number}`]).join(' | ');
  }

  const getMenuItems = ({status, id}: ReturnsItem): MenuItem[][] => {
    const primaryActions = [
      ['Mapping Needed', 'Review Assets'].includes(status) && {
        'Edit Assets': () => {
          navigate(`/returns/editAccountMappingAndTaxability/${id}`);
        },
      },
      status === 'Review Assets' && {
        'Approve': () => {
          setSelected([{ status, id} as ReturnsItem]);
          setApprovingSingleAsset(true);
        }
      },
      status === 'Approved' &&
      type === RETURNSTYPE.PreviewForms && {
        'Preview Form': () => {
          navigate(`/returns/previewReturnForm/${id}`);
        },
      },
      status === 'Approved' &&
      type === RETURNSTYPE.FilePackages && {
        'Preview Return Package': () => {
          navigate(`previewPackage/${id}`);
        },
        'Download return package and mark as filed': ({
          changeShowState,
        }: {
          changeShowState: Function1<boolean, void>;
        }) => {
          setConfirmId(id);
          setIsConfirmOpen(true);
          changeShowState(false);
        },
      },
      status === 'Filed' && {
        'Download filed Return Package': () => {
          setDownloadId(id, true);
          downloadFiledReturnPackage(id)
            .then((target) => {
              return exportPdf(target, () => {
                setDownloadId(id, false);
              });
            })
            .catch(() => {
              setDownloadId(id, false);
            });
        },
      },
    ];

    const secondaryActions = [
      status === 'Approved' &&
      (type === RETURNSTYPE.FilePackages || type === RETURNSTYPE.PreviewForms) && {
        Unapprove: () => {
          setUnapproving({
            ...unapproving,
            [id]: true,
          });
          unapproveAsset(id)
            .then((res) => {
              lastQueryRef.current = undefined;
              const errorMessage = getErrorMessage(res);
              if (errorMessage) {
                toastRef.current?.showErrorToast(errorMessage);
              } else {
                setItems(
                  items.map((x) => (x.id === res.data.id ? res.data : x)),
                );
                toastRef.current?.showSuccessToast(
                  'Unapprove return successfully',
                );
              }
              setUnapproving({
                ...unapproving,
                [id]: false,
              });
              fetchData(_.noop);
            })
            .catch((e) => {
              toastRef.current?.showErrorToast(getErrorMessage(e));
              setUnapproving({
                ...unapproving,
                [id]: false,
              });
            });
        },
      },
      status === 'Filed' &&
      type === RETURNSTYPE.FilePackages && {
        Unfile: () => {
          setConfirmId(id);
          setIsConfirmUnfileOpen(true);
        },
      },
    ];

    return [
      map(assign({}, ...compact(primaryActions)), (onClick, label) => {
        let loading = false;
        // ignore in coverage report because the label can not be 'Approve'
        // or 'Unapprove'
        /* istanbul ignore else */
        if (label === 'Download filed Return Package') {
          loading = downloading[id];
        } else if (label === 'Approve') {
          loading = approving[id];
        } else if (label === 'Unapprove') {
          loading = unapproving[id];
        }
        return {
          variant: 'default',
          tooltip: {
            section: TOOLTIP_SECTIONS.TableRowAction,
            keyword: label,
          },
          label,
          onClick,
          loading,
        } as MenuItem;
      }),

      map(
        assign({}, ...compact(secondaryActions)),
        (onClick, label) =>
        ({
          variant:
            label === 'Approve'
              ? // ignore in coverage report because the label can not be 'Approve'
                  /* istanbul ignore next */ 'primary'
              : 'secondary',
          tooltip: {
            section: TOOLTIP_SECTIONS.TableRowAction,
            keyword: label,
          },
          label: label,
          onClick,
        } as MenuItem),
      ),
    ].filter((arr) => !isEmpty(arr));
  };

  const fetchData = (finish: () => void) => {
    const formattedQuery = {
      page: query?.pagination?.page ?? 1,
      perPage: query?.pagination?.pageSize ?? 10,
      sortBy: last(keys(query?.order)),
      sortDirection: last(values(query?.order)),
      taxYear: selectedSystemTaxYear.taxYear,
      statuses: !isEmpty(query?.filter?.status)
        ? ([query?.filter?.status] as string[])?.join(',') ??
          // ignore in coverage report because [query?.filter?.status].join(',')
          // is always return valid value
          /* istanbul ignore next */ ''
        : (getStatuses(type) as string[])?.join(','),
      dateRange: query?.filter?.[
        'assessorAccountData.assessor.taxYears[0].returnsDeadline'
      ] as string[],
      assessorIds:
        (query?.filter?.['assessorAccountData.assessor.name'] as unknown[])?.join(
          ',',
        ) ?? '',
      stateIds:
        (
          query?.filter?.[
          'assessorAccountData.property.address.state.abbreviation'
          ] as unknown[]
        )?.join(',') ?? '',
      text: query?.search,
      includeReturnsWithNoAssets
    };
    if (!_.isEqual(formattedQuery, lastQueryRef.current)) {
      lastQueryRef.current = formattedQuery;
      requestCancelRef.current?.abort();
      requestCancelRef.current = new AbortController();
      setFetchingData(true);
      getReturns(formattedQuery, requestCancelRef.current.signal).then(
          (res) => {
            setItems(res.data.items || []);
            setTotal(res.data.total);
            const ids = keyBy(res.data.items, 'id');
            setSelected(selected.filter((x) => x?.id in ids));
            setFetchingData(false);
          },
        );
    } else {
      finish();
    }
  };

  const setQueryHanele = (x: Query) => {
    setQuery(x);
  };

  function reset() {
    setQuery({filter: {status: getStatuses(type)}});
  }

  useEffect(() => {
    const itemsByKey = keyBy(items, (x) => x.id);
    const updated = selected.map((x) => itemsByKey[x?.id] ?? x);

    if (some(updated, (x, idx) => x !== selected[idx])) {
      setSelected(compact(updated));
    }
  }, [items]);

  useEffect(() => {
    if (!_.isEmpty(query)) {
      fetchData(_.noop);
    }
  }, [type, query, selectedSystemTaxYear, includeReturnsWithNoAssets]);

  useEffect(() => {
    if (props.deadline && moment(props.deadline).isValid()) {
      const newQuery: Query = {...query};
      newQuery.filter = { ...newQuery.filter, 'assessorAccountData.assessor.taxYears[0].returnsDeadline': [props.deadline, props.deadline] };
      setQueryHanele(newQuery);
    }
  }, [props]);

  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isConfirmUnfileOpen, setIsConfirmUnfileOpen] = useState(false);
  const [isBulkConfirmOpen, setIsBulkConfirmOpen] = useState(false);
  const [mergeBulkPdfs, setMergeBulkPdfs] = useState(false);
  const [continuing, setContinuing] = useState(false);
  const [userPurchas, setUserPurchas] = useState<PurchasedReturnYearExt>();
  const onRequestClose = () => {
    setIsOpen(false);
  };

  const onContinue = () => {
    setContinuing(true);
    markAssetAsFilled(confirmId as number)
      .then((res) => {
        const errorMessage = getErrorMessage(res);
        if (errorMessage) {
          toastRef.current?.showErrorToast(errorMessage);
          return null;
        } else {
          toastRef.current?.showSuccessToast('Update successfully');
          return setItems(
            items.map((x) => (x.id === res.data.id ? res.data : x)),
          );
        }
      })
      .then(() => {
        return isFreeTrial
          ? null
          : downloadFiledReturnPackage(confirmId as number);
      })
      .then((target) => {
        return target ? exportPdf(target, _.noop) : null;
      })
      .then(() => {
        setContinuing(false);
        setIsOpen(false);
        setConfirmId(null);
      })
      .catch((e) => {
        toastRef.current?.showErrorToast(getErrorMessage(e));
        setContinuing(false);
        setIsOpen(false);
        setConfirmId(null);
      });
  };

  function bulkApproveAssets() {
    if (idsSelectedReviewAssets.length > 0) {
      updateApprovingAsset(idsSelectedReviewAssets, true);
      approveBulkAsset(idsSelectedReviewAssets)
        .then((res) => {
          lastQueryRef.current = undefined;
          const errorMessage = getErrorMessage(res);
          if (errorMessage) {
            toastRef.current?.showErrorToast(errorMessage);
          } else {
            const results = res.data;
            if (results.length) {
              setItems(
                items.map((x) => {
                  const matchItem = _.find(results, {
                    id: x.id,
                  });
                  return matchItem ? matchItem : x;
                }),
              );
            }
            fetchData(_.noop);
            toastRef.current?.showSuccessToast(
              'Approve return successfully',
            );
          }
          updateApprovingAsset(idsSelectedReviewAssets, false);
          reset();
        })
        .catch((e) => {
          toastRef.current?.showErrorToast(getErrorMessage(e));
          updateApprovingAsset(idsSelectedReviewAssets, false);
        });
    }
  }

  function bulkMarkAsFiledReturns() {
    if (idsSelectedApproved.length > 0) {
      setContinuing(true);
      setBulkFiling(true);
      bulkMarkReturnAsFiled(idsSelectedApproved)
        .then((res) => {
          lastQueryRef.current = undefined;
          const errorMessage = getErrorMessage(res);
          if (errorMessage) {
            toastRef.current?.showErrorToast(errorMessage);
          } else {
            const results = res.data;
            if (results.length) {
              setItems(
                items.map((x) => {
                  const matchItem = _.find(results, {
                    id: x.id,
                  });
                  return matchItem ? matchItem : x;
                }),
              );
            }
            fetchData(_.noop);
            toastRef.current?.showSuccessToast(
              'Returns successfully marked as Filed',
            );
          }
          reset();
        })
        .then(() => {
          return isFreeTrial
            ? null
            : bulkDownloadFiledReturnPackage({ ids: (idsSelectedApproved as number[]).join(',') ?? '', mergePdfs: mergeBulkPdfs });
        })
        .then((target) => {
          return target ? exportPdf(target, _.noop) : null;
        })
        .then(() => {
          setConfirming(false);
          setIsBulkConfirmOpen(false);
          setConfirmId(null);
          setBulkFiling(false);
          setContinuing(false);
          setIsOpen(false);
        })
        .catch((e) => {
          toastRef.current?.showErrorToast(getErrorMessage(e));
          setConfirming(false);
          setIsConfirmOpen(false);
          setConfirmId(null);
          setBulkFiling(false);
          setContinuing(false);
          setIsOpen(false);
        });
    }
  }

  function unfile(returnId: number) {
    setConfirmingUnfile(true);

    setUnfiling({
      ...unfiling,
      [returnId]: true,
    });

    unfileReturn(returnId)
      .then((res) => {
        const errorMessage = getErrorMessage(res);
        if (errorMessage) {
          toastRef.current?.showErrorToast(errorMessage);

          setConfirmingUnfile(false);
          setIsConfirmUnfileOpen(false);
          setConfirmId(null);
          setUnfiling({
            ...unfiling,
            [returnId]: false,
          });

          return null;
        } else {
          toastRef.current?.showSuccessToast('Return unfiled successfully');

          setConfirmingUnfile(false);
          setIsConfirmUnfileOpen(false);
          setConfirmId(null);
          setUnfiling({
            ...unfiling,
            [returnId]: false,
          });

          lastQueryRef.current = undefined;
          fetchData(_.noop);
        }
      })
      .catch((e) => {
        toastRef.current?.showErrorToast(getErrorMessage(e));
        setConfirmingUnfile(false);
        setIsConfirmUnfileOpen(false);
        setConfirmId(null);
        setUnfiling({
          ...unfiling,
          [returnId]: false,
        });
      });
  }

  return (
    <>
      <Table
        id={`manageReturns-${camelCase(type)}`}
        rows={items}
        onQueryChanged={(x) => setQueryHanele(x)}
        paginate
        sortable
        selectable={{selected, onChange: setSelected}}
        searchable='Search returns...'
        totalRows={total}
        dynamicColumns
        loading={fetchingData}
        updatingTableTopbar={{
          downloading,
          approving,
          unapproving,
        }}
      >
        <TopBar>
          {selected.length > 0 ? (
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.TableAction}
              tooltipKey='Approve'
              className={style.lastAction}
            >
              <button
                type='button'
                className='primary'
                hidden={type!=='Approve Assets'}
                disabled={
                  isApprovingAsset(idsSelectedReviewAssets) ||
                  !canApproveSelected ||
                  fetchingData
                }
                onClick={bulkApproveAssets}
              >
                {`Approve (${selected.length})`}
                {approveBar()}
              </button>
            </TooltipWrapper>
          ) : null}
          {selected.length > 0 ? (
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.TableAction}
              tooltipKey='Mark as Filed'
              className={style.lastAction}
            >
              <button
                type='button'
                className='primary'
                hidden={type!=='File Packages'}
                disabled={
                  isApprovingAsset(idsSelectedApproved) 
                  || !canMarkAsFiledSelected
                  || fetchingData
                }
                onClick={() => {
                  setIsBulkConfirmOpen(true);
                  setBulkFiling(true);
                }}
              >
                {`Mark as Filed (${selected.length})`}
                {approveBar()}
              </button>
            </TooltipWrapper>
          ) : null}
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.TableAction}
            tooltipKey='Manage Global Asset Class'
            className={style.lastAction}
          >
            <button
              type='button'
              className='primary'
              onClick={() => navigate('/forms')}
            >
              Manage Global Asset Class Mapping
            </button>
          </TooltipWrapper>
        </TopBar>

        <Column
          label='Status'
          prop='status'
          filterable
          editor={{
            type: InputType.Select,
            options: getStatuses(type),
            multi: true,
          }}
        >
          {(status: string) => (
            <span className={classNames('badge-status', kebabCase(status))}>
              {status}
            </span>
          )}
        </Column>

        <Column
          id='assessorAccountData.assessor.taxYears[0].returnsDeadline'
          label='Return deadline'
          accessor={(item) =>
            item.status === 'Filed' ?
            item.assessorAccountData?.assessor?.taxYears?.[0].returnsDeadline :
            item.assessorAccount?.assessor?.taxYears?.[0].returnsDeadline
          }
          filterable
          editor={{
            type: InputType.DateRange,
            label: ['Return deadline From Date', 'Return deadline To Date'],
          }}
        >
          {formatDate}
        </Column>

        <Column
          id='assessorAccountData.property.address.state.abbreviation'
          label='State'
          accessor={(item) =>
            item.status === 'Filed' ?
            item.assessorAccountData?.property.address.state?.abbreviation ?? '' :
            item.assessorAccount?.property.address.state?.abbreviation ?? ''
          }
          filterable
          editor={{
            type: InputType.Select,
            multi: true,
            options: stateOptions,
            searchable: true,
          }}
        />

        <Column
          id='assessorAccountData.assessor.name'
          label='Assessor'
          accessor={(item) => 
            item.status === 'Filed' ?
            item.assessorAccountData?.assessor?.name ?? '' :
            item.assessorAccount?.assessor?.name ?? ''
          }
          filterable
          editor={{
            type: InputType.Select,
            options: assessors,
            getLabel: (x) => (x as Assessor).name,
            getValue: (x) => (x as Assessor).id,
            multi: true,
            searchable: true,
          }}
        ></Column>

        <Column
          id='assessorAccountData.number'
          label='Account number'
          accessor={(item) => 
            item.status === 'Filed' ?
            item.assessorAccountData?.number ?? '' :
            item.assessorAccount?.number ?? ''
          }
        ></Column>

        <Column
          id='propertyNameAndNumber'
          label='Property name & number'
          accessor={(item) =>
            item.status === 'Filed' ?
            formatNameAndNumber(item.assessorAccountData?.property.name, item.assessorAccountData?.property.number) :
            formatNameAndNumber(item.assessorAccount?.property.name, item.assessorAccount?.property.number)
          }
        ></Column>

        <Column
          id='totalReportedCost'
          label='Total reported cost'
          cssClasses='text-align-right'
        >
          {(returnItem: ReturnsItem) => (
            <div>
              {Math.round(returnItem.totalReportedCost) || ''}
            </div>
          )}
        </Column>

        <Column
          id='totalAssessedValue'
          label='Total assessed value'
          cssClasses='text-align-right'
        >
          {(returnItem: ReturnsItem) => (
            <div>
              {Math.round(returnItem.totalAssessedValue) || ''}
            </div>
          )}
        </Column>

        <Column
          label='Prior Year Total Reported Cost'
          prop='priorYearTotalReportedCost'
          hidden
        ></Column>

        <Column
          label='Prior Year Total Assessed Value'
          prop='priorYearTotalAssessedValue'
          hidden
        ></Column>

        <Column
          id='companyNameAndNumber'
          label='Company Name & Number'
          accessor={(item) =>
            item.status === 'Filed' ?
            formatNameAndNumber(item.assessorAccountData?.property.company?.name, item.assessorAccountData?.property.company?.name) :
            formatNameAndNumber(item.assessorAccount?.property.company?.name, item.assessorAccount?.property.company?.name)
          }
          hidden
        ></Column>

        <Column
          id='legalEntityNameAndNumber'
          label='Legal Entity Name & Number'
          accessor={(item) =>
            item.status === 'Filed' ?
            formatNameAndNumber(item.assessorAccountData?.property.legalEntity?.name, item.assessorAccountData?.property.legalEntity?.name) :
            formatNameAndNumber(item.assessorAccount?.property.legalEntity?.name, item.assessorAccount?.property.legalEntity?.name)
          }
          hidden
        ></Column>

        <Column id='actions' label='Actions' sortable={false}>
          {(item: ReturnsItem) => (
            <div className={style['actions-column-cell']}>
              <TableMenu
                toggle={
                  <button type='button'>
                    <i className={style['icon-3-dots']} />
                  </button>
                }
                menuItems={getMenuItems(item)}
              />
            </div>
          )}
        </Column>

        <Column
          id='taxYear'
          label='Tax Year'
          hidden='always'
        ></Column>
      </Table>
      <ConfirmModal
        isOpen={isConfirmOpen}
        onRequestClose={() => {
          setIsConfirmOpen(false);
        }}
        title='Confirmation'
        content='Are you sure you want to mark the return as filed?'
        confirming={confirming}
        onConfirm={() => {
          setConfirming(true);
          getUserProfile().then((d) => {
            const userPurchases = _.orderBy(
              d.data.userAccount.userPurchases,
              ['taxYear'],
              ['desc'],
            );
            setUserPurchas(userPurchases[0]);
            if (
              userPurchases[0].returnsPurchased <= userPurchases[0].returnsUsed
            ) {
              setIsOpen(true);
              setIsConfirmOpen(false);
              setConfirming(false);
            } else {
              markAssetAsFilled(confirmId as number)
              .then((res) => {
                const errorMessage = getErrorMessage(res);
                if (errorMessage) {
                  toastRef.current?.showErrorToast(errorMessage);
                  return null;
                } else {
                  toastRef.current?.showSuccessToast('Update successfully');
                  return setItems(
                    items.map((x) => (x.id === res.data.id ? res.data : x)),
                  );
                }
              })
              .then(() => {
                return isFreeTrial
                  ? null
                  : downloadFiledReturnPackage(confirmId as number);
              })
              .then((target) => {
                return target ? exportPdf(target, _.noop) : null;
              })
              .then(() => {
                setConfirming(false);
                setIsConfirmOpen(false);
                setConfirmId(null);
              })
              .catch((e) => {
                toastRef.current?.showErrorToast(getErrorMessage(e));
                setConfirming(false);
                setIsConfirmOpen(false);
                setConfirmId(null);
              });
            }
          });
        }}
      />

      {/* Unfile confirm modal */}
      <ConfirmModal
        isOpen={isConfirmUnfileOpen}
        onRequestClose={() => {
          setIsConfirmUnfileOpen(false);
        }}
        title='Confirmation'
        content='Are you sure you want to unfile this return? The original return package will be deleted. If a copy is required, Cancel and download the return package before unfiling.'
        confirming={confirmingUnfile}
        onConfirm={() => {
          unfile(confirmId as number);          
        }}
      />

      {/* Bulk Return Confirm Modal */}
      <BaseModal 
        isOpen={isBulkConfirmOpen}
        onRequestClose={() => {
          setIsBulkConfirmOpen(false);
        }}
      >
        <div className={style['modal']}>
          <div className={style['modal-header']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Confirmation'
            >
              <span>Confirmation</span>
            </TooltipWrapper>
          </div>
          <div className={style['modal-content']}>
            <div>Are you sure you want to mark these returns as filed? Remember, this action is irreversible.</div>
            <div className={style['merge-pdf-toggle']}>
              <FieldToggleCheckbox
                label="Merge Return PDFs"
                value={mergeBulkPdfs}
                onChange={(newVal) => {
                  setMergeBulkPdfs(newVal);
                }}
                classnames={style.FieldToggleCheckbox}
              />
            </div>
          </div>
          <div className={style['modal-footer']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Continue'
            >
              <button
                type='button'
                className='primary'
                onClick={() => {
                  setConfirming(true);
                  getUserProfile().then((d) => {
                    const userPurchases = _.orderBy(
                      d.data.userAccount.userPurchases,
                      ['taxYear'],
                      ['desc'],
                    );
                    setUserPurchas(userPurchases[0]);
                    if (
                      userPurchases[0].returnsPurchased <= userPurchases[0].returnsUsed + idsSelectedApproved.length
                    ) {
                      setIsOpen(true);
                      setIsConfirmOpen(false);
                      setConfirming(false);
                    } else {
                      bulkMarkAsFiledReturns();
                    }
                  });
                }}
                disabled={continuing}
                ref={ref}
              >
                Confirm
                {confirming ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Cancel'
            >
              <button
                type='button'
                className='secondary'
                onClick={() => {
                  setIsBulkConfirmOpen(false);
                }}
              >
                Cancel
              </button>
            </TooltipWrapper>
          </div>
        </div>
      </BaseModal>

      {/* All returns used warning */}
      <BaseModal isOpen={isOpen} onRequestClose={onRequestClose}>
        <div className={style['modal']}>
          <div className={style['modal-header']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Warning'
            >
              <span>Warning</span>
            </TooltipWrapper>
          </div>
          <div
            className={style['modal-content']}
          >{`You have filed ${userPurchas?.returnsUsed}/${userPurchas?.returnsPurchased} returns. You will be billed for any additional returns filed for this Tax Year.`}</div>
          <div className={style['modal-footer']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Continue'
            >
              <button
                type='button'
                className='primary'
                onClick={idsSelectedApproved.length > 0 ? bulkMarkAsFiledReturns : onContinue}
                disabled={continuing}
                ref={ref}
              >
                Continue
                {continuing ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Cancel'
            >
              <button
                type='button'
                className='secondary'
                onClick={onRequestClose}
              >
                Cancel
              </button>
            </TooltipWrapper>
          </div>
        </div>
      </BaseModal>
      <Loader isOpen={fetchingAllAssessors} />
    </>
  );
}
