import classNames from 'classnames';
import {FormikValues, useFormik} from 'formik';
import _, {keys, last, values, isEqual} from 'lodash';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import ModalContext from '../../context/ModalContext';
import {EUserRoles, TOOLTIP_SECTIONS, USER_ROLES} from '../../enums';
import {
  ExtUserModal,
  IToastMessage,
  ReduxState,
  UserModal,
} from '../../interface';
import {createUser, deleteUser, getAllUsers, getErrorMessage, updateUser} from '../../services';
import {formatDate} from '../../utils/stringUtil';
import validateEmail from '../../utils/validateEmail';
import validateEmpty from '../../utils/validateEmpty';
import FieldInput from '../FieldInput/FieldInput';
import FieldSelect from '../FieldSelect/FieldSelect';
import { LoaderIndicator } from '../Loader/Loader';
import Modal from '../Modal/Modal';
import {InputType} from '../Table/components/Editor/Editor';
import InitTable, {Query} from '../Table/Table';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import style from './UserTable.module.scss';
import BaseModal from "../../components/BaseModal/BaseModal";
import DiscardChangeConfirmModal from '../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal'
import { useAppDispatch } from "../../redux/store";
import useUserProfile from '../../hook/useUserProfile';
import { getUserProfile } from "../../redux/features/auth-slice";

const {Table, Column, TopBar} = InitTable<UserModal>();

interface UserTableProps {
  setToastMessage: (message: IToastMessage) => void;
}

function UserTable(props: UserTableProps) {
  const {setToastMessage} = props;
  const userProfile = useUserProfile();
  const appDispatch = useAppDispatch();
  const [userList, setUserList] = useState<ExtUserModal[]>([]);

  const [total, setTotal] = useState<number>(0);
  const {state, dispatch} = useContext(ModalContext);
  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [toEditUser, setToEditUser] = useState<UserModal>();
  const [toDeleteUser, setToDeleteUser] = useState<UserModal | null>(null);
  const [navBlock, setNavBlock] = useState<boolean>(false);

  const lastQuery = useRef<Query>();

  const newUser = {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  };

  const requiredField = ['firstName', 'lastName', 'email', 'role'];

  const roleOptions = _(USER_ROLES)
    .filter((role) => 
      role !== EUserRoles.SystemAdministrator
      && role !== EUserRoles.SupportAdministrator
    )
    .map((role) => ({label: role, name: role, value: role}))
    .value();
  const [fetchingData, setFetchingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [query, setQuery] = useState<Query>({});


  const fetchData = useCallback(() => {
    setFetchingData(true);
    return getAllUsers({
      page: query.pagination?.page,
      perPage: query.pagination?.pageSize,
      sortBy: last(keys(query.order)),
      sortDirection: last(values(query.order)),
      text: query.search,
      roles: (query.filter?.roles as string) ?? '',
      createdAtFrom: (query.filter?.createdAt as string[])?.[0] ?? '',
      createdAtTo: (query.filter?.createdAt as string[])?.[1] ?? '',
    }).then((d) => {
      setUserList(_.map(d.data.items, (item) => ({...item, checked: false})));
      setTotal(d.data.total);
      setFetchingData(false);
    }).catch(() => {
      setFetchingData(false);
    });
  }, [query]);

  // Open delete confirm Dialog
  const deleteItem = (value: UserModal) => {
    setToDeleteUser(value);
    dispatch({type: 'OPEN', href: 'confirm'});
  };

  const [deletingUser, setDeletingUser] = useState(false)
  // Delete item
  const deleteConfirm = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (toDeleteUser) {
      setDeletingUser(true);
      deleteUser(toDeleteUser.id).then((d) => {
        const resError = getErrorMessage(d);
        if (resError) {
          setToastMessage({
            visible: true,
            message: resError,
            type: 'error',
          });
        } else {
          setToastMessage({
            visible: true,
            message: 'Delete user successfully',
            type: 'success',
          });
          dispatch({type: 'CLOSE'});
          fetchData();
        }
        setDeletingUser(false);
      }).catch((err) => {
        setToastMessage({
          visible: true,
          message: getErrorMessage(err),
          type: 'error',
        });
        setDeletingUser(false);
      });
    }
  };

  const validate = (formikValues: FormikValues) => {
    // eslint-disable-next-line
    const errors: any = _.defaults(
      validateEmpty(_.pick(_.omit(formikValues, 'role'), requiredField)),
      validateEmail(_.pick(formikValues, ['email'])),
      {role: formikValues?.roles?.length ? null : 'Empty Field'}
    );

    let result = true;
    for (const key in errors) {
      const error = errors[key];
      if (error && error.length > 0) {
        result = false;
      }
    }

    if (!result) {
      return errors;
    }
  };

  const formik = useFormik<FormikValues>({
    initialValues: newUser,
    enableReinitialize: true,
    onSubmit: (formValues) => {
      setErrorMessage('');
      // if there are errors, show them
      const errors = validate(formValues);
      if (errors) {
        formik.setErrors(errors);
        return;
      }

      if (isCreate) {
        setSubmitting(true);

        createUser(formValues).then((d) => {
          const resError = getErrorMessage(d);
          if (resError) {
            setErrorMessage(resError);
          } else {
            setToastMessage({
              visible: true,
              message: 'Add user successfully',
              type: 'success'
            });
            dispatch({type: 'CLOSE'});
            setToEditUser(undefined);
            formik.resetForm();
            fetchData();
          }
          setSubmitting(false);
        }).catch((err) => {
          setSubmitting(false);
          setErrorMessage(getErrorMessage(err));
        });
      } else {
        setSubmitting(true);
        updateUser(formValues.id, formValues).then((d) => {
          if (userProfile?.id === formValues.id) {
            appDispatch(getUserProfile());
          }
          const resError = getErrorMessage(d);
          if (resError) {
            setErrorMessage(resError);
          } else {
            setToastMessage({
              visible: true,
              message: 'Edit user successfully',
              type: 'success'
            });
            dispatch({type: 'CLOSE'});
            setToEditUser(undefined);
            formik.resetForm();
            fetchData();
          }
          setSubmitting(false);
        }).catch((err) => {
          setSubmitting(false);
          setErrorMessage(getErrorMessage(err));
        });
      }
    },
  });

  useEffect(() => {
    if (toEditUser) {
      setNavBlock(!isEqual(toEditUser, formik.values));
    } else {
      setNavBlock(false);
    }
  }, [toEditUser, formik.values]);

  const ref = useRef<HTMLButtonElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    ref.current && ref.current.blur();
    saveRef.current && saveRef.current.blur();
  }, [state.isOpen]);

  useEffect(() => {
    if (!isEqual(query, lastQuery.current) && !_.isEmpty(query)) {
      fetchData();
    }
  }, [query]);

  const setQueryHanele = (x: Query) => {
    setQuery(x);
  };

  return (
    <>
      <div className={style['table-layout']}>
        <Table
          id='userManagement'
          rows={userList}
          onQueryChanged={(x) => setQueryHanele(x)}
          paginate
          sortable
          searchable='Search...'
          totalRows={total}
          dynamicColumns
          loading={fetchingData}
        >
          {/* Top Bar */}
          <TopBar>
            <div className={style['add-user-button']}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.TableAction}
                tooltipKey='Add User'
              >
                <button
                  className={classNames(
                    'primary-button',
                    'add-button',
                    style.btnAdd,
                  )}
                  onClick={() => {
                    setIsCreate(true);
                    // eslint-disable-next-line
                    setToEditUser(newUser as any);
                    setErrorMessage('');
                    dispatch({type: 'OPEN', href: 'addUser'});
                  }}
                >
                  <i></i>Add User
                </button>
              </TooltipWrapper>
            </div>
          </TopBar>

          {/* Name column */}
          <Column label='Name' prop='fullName' />

          {/* Email column */}
          <Column label='Email' prop='email' />

          {/* Role column */}
          <Column
            label='Roles'
            prop='roles'
            filterable
            editor={{
              type: InputType.Select,
              options: USER_ROLES,
              nullOption: 'All',
            }}
            sortable={false}
          >
            {(roles: string[]) =>
              _.map(roles, (role, key) => <div key={key}>{role}</div>)
            }
          </Column>

          {/* Date column */}
          <Column
            label='Date Added'
            prop='createdAt'
            filterable
            editor={{
              type: InputType.DateRange,
              label: ['From Date', 'To Date'],
            }}
          >
            {formatDate}
          </Column>

          {/* Actions column  */}
          <Column id='actions' label='Actions' sortable={false}>
            {(userData: UserModal) => (
              <div className={style['operate']}>
                <i
                  role='button'
                  className='edit'
                  onClick={() => {
                    setIsCreate(false);
                    formik.setValues(userData);
                    setToEditUser(userData);
                    setErrorMessage('');
                    dispatch({type: 'OPEN', href: 'addUser'});
                  }}
                ></i>
                <i
                  role='button'
                  className='delete'
                  onClick={() => deleteItem(userData)}
                ></i>
              </div>
            )}
          </Column>
        </Table>
      </div>

      <BaseModal isOpen={!!toEditUser} className={style["modal"]}>
        <div className={style["modal-wrapper"]}>
          <h3>{isCreate ? 'Add New User' : 'Edit User'}</h3>
          <div className={classNames('modal-body', style['add-edit-user-modal'])}>
              <form>
                <FieldInput
                  labelText='First Name'
                  name='firstName'
                  classnames={style.fieldInput}
                  value={formik.values.firstName}
                  onChange={(value) => {
                    formik.setFieldTouched('firstName', true);
                    formik.setFieldValue('firstName', value);
                  }}
                  error={formik.errors && (formik.errors as FormikValues).firstName}
                  required
                />
                <FieldInput
                  labelText='Last Name'
                  name='lastName'
                  classnames={style.fieldInput}
                  value={formik.values.lastName}
                  onChange={(value) => {
                    formik.setFieldTouched('lastName', true);
                    formik.setFieldValue('lastName', value);
                  }}
                  error={formik.errors && (formik.errors as FormikValues).lastName}
                  required
                />
                <FieldInput
                  labelText='Email'
                  name='email'
                  classnames={style.fieldInput}
                  value={formik.values.email}
                  onChange={(value) => {
                    formik.setFieldTouched('email', true);
                    formik.setFieldValue('email', value);
                  }}
                  error={formik.errors && (formik.errors as FormikValues).email}
                  required
                  disabled={!isCreate}
                />
                <FieldSelect
                  labelText='Role'
                  options={roleOptions}
                  force
                  multiple
                  noSearch
                  selectIds={formik.values.roles}
                  onMultipleSelect={(roles) => {
                    formik.setFieldValue('roles', _.map(roles, 'label'));
                  }}
                  onSelect={_.noop}
                  error={formik.errors && (formik.errors as FormikValues).role}
                  required
                />
              </form>
              {errorMessage && <div className={style.error}>{errorMessage}</div>}
          </div>
          <div
            className={classNames(style.footer, 'modal-footer')}
          >
            <div className={style.buttons}>
              <button
                className='primary-button'
                onClick={() => formik.submitForm()}
                disabled={submitting}
                ref={saveRef}
              >
                Save{submitting ? (<LoaderIndicator className='button-loading' loading={true} />) : null}
              </button>
              <button
                className='default-button'
                onClick={() => {
                  if (navBlock) {
                    dispatch({type: 'OPEN', href: 'confirm-leave'});
                  } else {
                    setToEditUser(undefined);
                    formik.resetForm();
                    setNavBlock(false);
                  }
                }}
                disabled={submitting}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </BaseModal>

      <Modal
        title='Confirmation'
        body={<p>Are you sure you want to delete?</p>}
        isOpen={state.isOpen && state.href === 'confirm'}
        footer={
          <div className='buttons'>
            <button
              className='primary-button'
              onClick={(e) => deleteConfirm(e)}
              disabled={deletingUser}
              ref={ref}
            >
              Confirm{deletingUser ? (<LoaderIndicator className='button-loading' loading={true} />) : null}
            </button>
            <button
              className='default-button'
              onClick={() => {
                setToDeleteUser(null);
                dispatch({type: 'CLOSE'});
                setNavBlock(false);
              }}
            >
              Cancel
            </button>
          </div>
        }
      />
      <DiscardChangeConfirmModal
        initialData={{}}
        inputData={{}}
        navBlock={navBlock}
        className={classNames(style['confirmModal'], style['innerModal'])}
        onConfirm={() => {
          dispatch({type: 'CLOSE'});
          setToEditUser(undefined);
          formik.resetForm();
        }}
        innerModal={true}
      />
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
});

export default connect(mapStateToProps)(UserTable);
