import React, { useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { FormikValues, useFormik } from 'formik';
import FieldInput from '../FieldInput/FieldInput';
import validateEmpty from '../../utils/validateEmpty';
import validateEmail from '../../utils/validateEmail';
import { LoaderIndicator } from '../Loader/Loader';
import FieldSelect from '../FieldSelect/FieldSelect';
import { EUserRoles, USER_ROLES } from '../../enums';
import style from './UserAdminForm.module.scss';

const requiredField = [
  'firstName',
  'lastName',
  'email',
  'roles'
];

interface UserAdminUI {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  roles: undefined | string[];
  isActive?: boolean;
  customerAccountIds?: string[];
}
interface Props {
  inEditMode?: boolean;
  data: UserAdminUI;
  onSubmit: (data: UserAdminUI) => void;
  onClear: () => void;
  submitting: boolean;
  errorMessage?: string;
}

function UserAdminForm({
  inEditMode = false,
  data,
  onSubmit,
  onClear,
  submitting,
  errorMessage,
}: Props) {
  const [navBlock, setNavBlock] = useState<boolean>();

  const checkError = (errors: any) => {
    let hasError =
      Object.keys(errors).filter((x) => x !== 'roles').length > 0;
    if (errors.roles) {
      hasError = true;
    }
    return hasError;
  };
  

  const roleOptions = _(USER_ROLES)
    .filter((role) => 
          role === EUserRoles.SystemAdministrator || 
          role === EUserRoles.SupportAdministrator
     )
    .map((role) => ({label: role, name: role, value: role}))
    .value();


  const formik = useFormik<UserAdminUI>({
    initialValues: {
      ...data,
      roles: inEditMode ? data?.roles : [],
    },
    enableReinitialize: true,
    validate: (values) => {
      // eslint-disable-next-line
      const errors: any = _.defaults(
        validateEmpty(_.pick(_.omit(values, 'roles'), requiredField)),
        validateEmail(_.pick(values, ['email'])),
        {roles: values?.roles?.length ? null : 'Empty Field'}
      );
      return _.defaults(errors);
    },
    onSubmit: _.noop,
  });
  return (
    <>
      <form
        className={style['main']}
        onSubmit={(e) => {
          e.preventDefault();
          formik.setTouched({
            firstName: true,
            lastName: true,
            email: true,
            roles: true,
          });
          formik.validateForm(formik.values).then((error) => {
            if (!checkError(error)) {
              setNavBlock(false);
              const updated = _.cloneDeep(formik.values);
              onSubmit(updated);
            }
          });
        }}
      >
        <div className={classNames(style['section'])}>
          <div className={classNames(style['form'])}>
            <div className={style['row']}>
              <div className={style['column']}>
                <div className={style['cell']}>
                  <FieldInput
                    labelText='First Name'
                    name='firstName'
                    value={formik.values.firstName}
                    onChange={(value) => {
                      formik.setFieldTouched('firstName', true);
                      formik.setFieldValue('firstName', value);
                    }}
                    maxLength={50}
                    error={
                      formik.errors &&
                      (formik.errors as FormikValues).firstName
                    }
                    required
                  />
                </div>
                <div className={style['cell']}>
                  <FieldInput
                    labelText='Last Name'
                    name='lastName'
                    value={formik.values.lastName}
                    onChange={(value) => {
                      formik.setFieldTouched('lastName', true);
                      formik.setFieldValue('lastName', value);
                    }}
                    maxLength={50}
                    error={
                      formik.errors &&
                      (formik.errors as FormikValues).lastName
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div className={style['row']}>
              <div className={style['column']}>
                <div className={style['cell']}>
                  <FieldInput
                    labelText='Email Address'
                    name='email'
                    value={formik.values.email}
                    onChange={(value) => {
                      formik.setFieldTouched('email', true);
                      formik.setFieldValue('email', value);
                    }}
                    maxLength={50}
                    error={
                      formik.errors &&
                      (formik.errors as FormikValues).email
                    }
                    required
                    disabled={inEditMode}
                  />
                </div>
                  <FieldSelect
                    labelText='Role'
                    options={roleOptions}
                    noSearch
                    selectIds={formik.values.roles}
                    onMultipleSelect={(roles) => {
                      formik.setFieldTouched('roles', true);
                      formik.setFieldValue('roles', _.map(roles, 'label')); 
                    }}
                    onSelect={(roles) => {formik.setFieldValue('roles',_.toArray(roles?.value ? [roles.value] : null))}}
                    error={formik.errors && (formik.errors as FormikValues).roles}
                    required
                    disabled={inEditMode}
                  />
              </div>
            </div>
          </div>
        </div>
        {errorMessage && (
          <div className={style.error}>
            <span>{errorMessage}</span> 
          </div>
        )}
        <div className={style['footer']}>
          <div className={style['buttons']}>
            <button
              type='submit'
              className='primary-button'
              disabled={submitting}
            >
              Save
              {submitting ? (
                <LoaderIndicator className='button-loading' loading={true} />
              ) : null}
            </button>
            <button
              type='button'
              onClick={() => {
                formik.resetForm();
                onClear();
              }}
              className='default-button'
              disabled={submitting}
            >
              {'Cancel'}
            </button>
          </div>
        </div>
      </form>
     
    </>
  );
}

export default UserAdminForm;
