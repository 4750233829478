/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useRef, useEffect} from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {FormikValues, useFormik} from 'formik';
import FieldInput from '../FieldInput/FieldInput';
import validateEmpty from '../../utils/validateEmpty';
import {PurchasedReturnYear, PurchaseReturnUI, SelectOption, SystemTaxYear} from '../../interface';
import style from './PurchaseReturnForm.module.scss';
import validateEmail from '../../utils/validateEmail';
import {LoaderIndicator} from '../Loader/Loader';
import DiscardChangeConfirmModal, {
  DiscardChangeConfirmModalMethods,
} from '../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';
import FieldDate from '../FieldDate/FieldDate';
import {getToday} from '../../utils/date';
import {EUserRoles, USER_ROLES} from '../../enums';
import FieldSelect from '../FieldSelect/FieldSelect';
import { getSystemTaxYears } from '../../services';

// Define the required fields
const requiredField = [
  'businessName',
  'firstName',
  'lastName',
  'email',
  'taxYear',
  'returnsPurchased',
];
const emailField = ['email'];
const YEAR_ERROR_MESSAGE =
  'Tax Year & Purchase Amount should be entered together';
const YEAR_DUPLICATE_ERROR_MESSAGE = 'Tax Year should not be duplicate';
// Defind the format fields

interface Props {
  inEditMode?: boolean;
  data: PurchaseReturnUI;
  onSubmit: (data: PurchaseReturnUI) => void;
  onClear: () => void;
  sectionClassName?: string;
  formClassName?: string;
  submitting: boolean;
  innerModal?: boolean;
  errorMessage?: string;
}

function PurchaseReturnForm({
  inEditMode = false,
  data,
  onSubmit,
  sectionClassName,
  formClassName,
  onClear,
  submitting,
  innerModal = false,
  errorMessage,
}: Props) {
  const confirmModalRef = useRef<DiscardChangeConfirmModalMethods>();
  const [navBlock, setNavBlock] = useState<boolean>();
  const [taxYearOptions, setTaxYearOptions] = useState<SelectOption[]>([]);

  // On component initialization
  useEffect(() => {
    getSystemTaxYears()
      .then((result) => {
        if (result.data.length === 0) return;

        // Set options for tax year dropdown
        const activeTaxYears = result.data
          .filter((taxYear: SystemTaxYear) => taxYear.isActive)
          .sort((taxYear1: SystemTaxYear, taxYear2: SystemTaxYear) => {
            if (taxYear1.taxYear < taxYear2.taxYear) {
              return 1;
            }
            if (taxYear1.taxYear > taxYear2.taxYear) {
              return -1;
            }
            return 0;
          });

        setTaxYearOptions(activeTaxYears.map((taxYear: SystemTaxYear) => { return { item: taxYear, name: taxYear.taxYear.toString(), label: taxYear.taxYear.toString(), value: taxYear.taxYear }; }));
      })
  }, []);

  const checkError = (errors: any) => {
    let hasError =
      Object.keys(errors).filter((x) => x !== 'userPurchases').length > 0;
    errors.userPurchases?.forEach((item: any) => {
      hasError = hasError || Object.keys(item).length > 0;
    });

    return hasError;
  };

  const roleOptions = _(USER_ROLES)
    .filter((role) => 
      role !== EUserRoles.SystemAdministrator 
      && role !== EUserRoles.SupportAdministrator
    )
    .map((role) => ({label: role, name: role, value: role}))
    .value();

  const formik = useFormik<PurchaseReturnUI>({
    initialValues: {
      ...data,
      freeTrialStartDate: data.freeTrialStartDate || getToday(),
      roles: inEditMode ? data?.roles : _.map(roleOptions, 'value'),
    },
    enableReinitialize: true,
    validate: (values) => {
      const requiredErrors = validateEmpty(_.pick(values, requiredField));
      const emailError = validateEmail(_.pick(values, emailField));
      const rolesError = !values.roles?.length
        ? {roles: 'Empty Field'}
        : undefined;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const yearError: any[] = [];
      const yearSet = new Set<number>();
      const duplicateYearSet = new Set<number>();
      values.userPurchases.forEach((item: PurchasedReturnYear) => {
        if (item.taxYear) {
          const isValidYear = taxYearOptions.filter(taxYear => taxYear.value === item.taxYear).length > 0;

          if (!isValidYear) {
            yearError.push({ 'taxYear': 'Invalid Year'});
            return;
          }

          if (item.returnsPurchased === 0) {
            yearError.push({returnsPurchased: YEAR_ERROR_MESSAGE});
          }

          yearSet.has(item.taxYear)
            ? duplicateYearSet.add(item.taxYear)
            : yearSet.add(item.taxYear);
        } else if (item.returnsPurchased !== 0) {
          yearError.push({taxYear: YEAR_ERROR_MESSAGE});
        }
      });

      // calculate duplicate year error
      values.userPurchases.forEach((item: PurchasedReturnYear, idx: number) => {
        if (duplicateYearSet.has(item.taxYear)) {
          yearError[idx]['taxYear'] = YEAR_DUPLICATE_ERROR_MESSAGE;
        }
      });
      return _.defaults(requiredErrors, emailError, rolesError, {
        userPurchases: yearError,
      });
    },
    onSubmit: _.noop,
  });

  const addYear = () => {
    const tempValue = _.cloneDeep(formik.values);

    tempValue.userPurchases.push({
      id: undefined,
      taxYear: 0,
      returnsPurchased: 0,
    });

    formik.setValues(tempValue);
  };

  const handleDelete = (index: number) => {
    const tempValue = _.cloneDeep(formik.values);

    tempValue.userPurchases.splice(index, 1);
    formik.setValues(tempValue);
  };

  return (
    <>
      <form
        className={style['main']}
        onSubmit={(e) => {
          e.preventDefault();
          formik.setTouched({
            businessName: true,
            firstName: true,
            lastName: true,
            email: true,
            userPurchases: formik.values.userPurchases?.map(() => ({
              taxYear: true,
              returnsPurchased: true,
            })),
            roles: true,
          });
          formik.validateForm(formik.values).then((error) => {
            if (!checkError(error)) {
              setNavBlock(false);

              const updated = _.cloneDeep(formik.values);
              const userPurchases: PurchasedReturnYear[] = [];
              updated.userPurchases?.forEach((item) => {
                if (item.taxYear && item.returnsPurchased) {
                  userPurchases.push(item);
                }
              });
              updated.userPurchases = userPurchases;

              if (!updated.freeTrialEndDate) {
                delete updated.freeTrialStartDate;
                delete updated.freeTrialEndDate;
              }

              onSubmit(updated);
            }
          });
        }}
      >
        <div className={classNames(sectionClassName, style['section'])}>
          <div className={classNames(formClassName, style['form'])}>
            <div className={style['row']}>
              <div className={classNames(style['column'])}>
                <FieldInput
                  classnames={style['businessNameField']}
                  labelText='Customer Account Name'
                  name='businessName'
                  value={formik.values.businessName}
                  onChange={(value) => {
                    formik.setFieldTouched('businessName', true);
                    formik.setFieldValue(`businessName`, value);
                  }}
                  maxLength={75}
                  error={
                    formik.touched &&
                    formik.touched.businessName &&
                    formik.errors &&
                    (formik.errors as FormikValues).businessName
                  }
                  required
                />
              </div>
            </div>
            <div className={style['row']}>
              <div className={style['column']}>
                <div className={style['cell']}>
                  <FieldInput
                    labelText='Customer Admin First Name'
                    name='firstName'
                    value={formik.values.firstName}
                    onChange={(value) => {
                      formik.setFieldTouched('firstName', true);
                      formik.setFieldValue(`firstName`, value);
                    }}
                    maxLength={50}
                    error={
                      formik.touched &&
                      formik.touched.firstName &&
                      formik.errors &&
                      (formik.errors as FormikValues).firstName
                    }
                    required
                  />
                </div>
                <div className={style['cell']}>
                  <FieldInput
                    labelText='Customer Admin Last Name'
                    name='lastName'
                    value={formik.values.lastName}
                    onChange={(value) => {
                      formik.setFieldTouched('lastName', true);
                      formik.setFieldValue(`lastName`, value);
                    }}
                    maxLength={50}
                    error={
                      formik.touched &&
                      formik.touched.lastName &&
                      formik.errors &&
                      (formik.errors as FormikValues).lastName
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div className={style['row']}>
              <div className={style['column']}>
                <div className={style['cell']}>
                  <FieldInput
                    labelText='Email Address'
                    name='email'
                    value={formik.values.email}
                    onChange={(value) => {
                      formik.setFieldTouched('email', true);
                      formik.setFieldValue(`email`, value);
                    }}
                    maxLength={50}
                    error={
                      formik.touched &&
                      formik.touched.email &&
                      formik.errors &&
                      (formik.errors as FormikValues).email
                    }
                    required
                    disabled={inEditMode}
                  />
                </div>
                <div className={style['cell']}>
                  <FieldSelect
                    labelText='Role'
                    options={roleOptions}
                    force
                    multiple
                    noSearch
                    selectIds={formik.values.roles}
                    onMultipleSelect={(roles) => {
                      formik.setFieldTouched('roles', true);
                      formik.setFieldValue('roles', _.map(roles, 'label'));
                    }}
                    onSelect={_.noop}
                    error={
                      formik.touched &&
                      formik.touched.roles &&
                      formik.errors &&
                      (formik.errors as FormikValues).roles
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <fieldset>
              <legend>Customer Accounts</legend>
              {formik.values.userPurchases?.map(
                (item: PurchasedReturnYear, index: number) => (
                  <div className={style['row']} key={index}>
                    <div className={style['column-row']}>
                      <div className={style['column-purchased']}>
                        <div className={style['cell-year']}>
                          <FieldSelect
                            labelText='Tax Year'
                            options={taxYearOptions} 
                            selectId={item.taxYear || null}
                            onSelect={(value) => {
                              formik.setFieldTouched(
                                `userPurchases[${[index]}].taxYear`,
                                true,
                              );
                              formik.setFieldValue(
                                `userPurchases[${[index]}].taxYear`,
                                Number(value?.value),
                              );
                            }}
                            error={
                              formik.touched &&
                              formik.touched.userPurchases &&
                              formik.touched.userPurchases.length > 0 &&
                              formik.touched.userPurchases[index]?.taxYear &&
                              formik.errors &&
                              (formik.errors as FormikValues).userPurchases &&
                              (formik.errors as FormikValues).userPurchases
                                .length > 0 &&
                              (formik.errors as FormikValues).userPurchases[
                                index
                              ]?.taxYear
                            }
                          />
                        </div>
                        <div className={style['cell-amount']}>
                          <FieldInput
                            labelText='Purchase Amount'
                            name={`userPurchases[${index}].returnsPurchased`}
                            type='number'
                            value={item.returnsPurchased}
                            onChange={(value) => {
                              formik.setFieldTouched(
                                `userPurchases[${[index]}].returnsPurchased`,
                                true,
                              );
                              formik.setFieldValue(
                                `userPurchases[${[index]}].returnsPurchased`,
                                Number(value),
                              );
                            }}
                            error={
                              formik.touched &&
                              formik.touched.userPurchases &&
                              formik.touched.userPurchases.length > 0 &&
                              formik.touched.userPurchases[index]
                                ?.returnsPurchased &&
                              formik.errors &&
                              (formik.errors as FormikValues).userPurchases &&
                              (formik.errors as FormikValues).userPurchases
                                .length > 0 &&
                              (formik.errors as FormikValues).userPurchases[
                                index
                              ]?.returnsPurchased
                            }
                          />
                        </div>
                      </div>
                      {formik.values.userPurchases.length > 1 && (
                        <div
                          className={classNames(style['operate'], 'operate')}
                        >
                          <i
                            role='button'
                            className={classNames(style['delete'])}
                            onClick={() => handleDelete(index)}
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                ),
              )}

              <div className={style['add-link-wrapper']}>
                <i
                  role='button'
                  className={classNames('link', style['add-link'])}
                  onClick={() => {
                    addYear();
                  }}
                >
                  Add Another Tax Year
                </i>
              </div>
            </fieldset>
          </div>
        </div>
        {errorMessage && (
          <div className={style.error}>
            <span>{errorMessage}</span>
          </div>
        )}
        <div className={style['footer']}>
          {/* Submit link,it change the flag of submit */}
          <div className={style['buttons']}>
            <button
              type='submit'
              className='primary-button'
              disabled={submitting}
            >
              Save
              {submitting ? (
                <LoaderIndicator className='button-loading' loading={true} />
              ) : null}
            </button>
            <button
              type='button'
              onClick={() => {
                if (inEditMode) {
                  if (confirmModalRef.current) {
                    confirmModalRef.current.openConfirmModal();
                  }
                } else {
                  formik.resetForm();
                }
              }}
              className='default-button'
              disabled={submitting}
            >
              {inEditMode ? 'Cancel' : 'Reset'}
            </button>
          </div>
        </div>
      </form>
      <DiscardChangeConfirmModal
        initialData={formik.initialValues}
        inputData={formik.values}
        onConfirm={() => {
          formik.resetForm();
          onClear();
        }}
        className={classNames(
          style['confirmModal'],
          innerModal && style['innerModal'],
        )}
        ref={confirmModalRef}
        navBlock={navBlock}
        innerModal={innerModal}
      />
    </>
  );
}

export default PurchaseReturnForm;
