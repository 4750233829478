import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import style from './AssessorManagementDetailsTable.module.scss';
import InitTable, { Query } from '../Table/Table';
import Loader, { LoaderIndicator } from '../Loader/Loader';
import { TOOLTIP_SECTIONS } from '../../enums';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import FieldSelect from '../FieldSelect/FieldSelect';
import { FormikValues, useFormik } from 'formik';
import { InputType } from '../Table/components/Editor/Editor';
import FieldToggleCheckbox from '../FieldToggleCheckbox/FieldToggleCheckbox';
import Divider from '../Divider/Divider';
import _ from 'lodash';
import { getDepreciationSchedules, getFormGroups, getIndexTables } from '../../services';
import { SelectOption } from '../../interface';
import { IBackendFormMapping } from '../../interface/IBackendFormMapping';
import { convertSelectObjectToMapping } from '../../utils/selectionOptions';

const { Table, Column } = InitTable<IBackendFormMapping>();

interface IUpdateSelectedAssetsForm {
  formGroup?: string;
  depreciationSchedule?: string;
  indexTable?: string;
  taxable?: boolean;
  reportable?: boolean;
}

interface Props {
  className?: string;
  setVisibleFilterPanel?: React.Dispatch<React.SetStateAction<"filter" | null>>;
  visibleFilterPanel?: "filter" | null;
  isInEditMode?: boolean;
  commonAssetClassOptions?: SelectOption[];
  formId?: number | null;
  taxYear?: number | null;
  depreciationScheduleCollectionId?: number | null;
  formMappingsList?: IBackendFormMapping[];
  searchStandardAssetClass?: string;
  updateUnsavedFormMappingsList?: (newValues: IBackendFormMapping[]) => void;
  saveMappingInfo?: (newValues: IBackendFormMapping[]) => void;
  indexTableCollectionId?: number | null;
}

const initialValues = {
  taxable: false,
  reportable: false,
} as IUpdateSelectedAssetsForm;
function AssessorManagementDetailsTable({
  className,
  setVisibleFilterPanel,
  visibleFilterPanel,
  isInEditMode = false,
  commonAssetClassOptions = [],
  formId,
  taxYear,
  depreciationScheduleCollectionId,
  formMappingsList,
  searchStandardAssetClass,
  updateUnsavedFormMappingsList,
  saveMappingInfo,
  indexTableCollectionId,
}: Props) {
  const [isSaving] = useState(false)

  const [selected, setSelected] = useState<IBackendFormMapping[]>([]);
  const [fetchingFormGroup, setFetchingFormGroup] =
    useState(false);
  const [fetchingDepreciationSchedule, setFetchingDepreciationSchedule] =
    useState(false);
  const [formGroupOptions, setFormGroupOptions] = useState<SelectOption[]>([]);
  const [depreciationScheduleOptions, setDepreciationScheduleOptions] = useState<SelectOption[]>([]);
  const [indexTableOptions, setIndexTableOptions] = useState<SelectOption[]>([]);
  const commonAssetClassMapping = useMemo(() => {
    return convertSelectObjectToMapping(commonAssetClassOptions);
  }, [commonAssetClassOptions]);
  const formGroupMapping = useMemo(() => {
    return convertSelectObjectToMapping(formGroupOptions);
  }, [formGroupOptions]);
  const depreciationScheduleMapping = useMemo(() => {
    return convertSelectObjectToMapping(depreciationScheduleOptions);
  }, [depreciationScheduleOptions]);
  const indexTableMapping = useMemo(() => {
    return convertSelectObjectToMapping(indexTableOptions);
  }, [indexTableOptions]);

  const [tableQuery, setTableQuery] = useState<Query>({});
  const [fetchingIndexTable, setFetchingIndexTable] =
    useState(false);

  const formik = useFormik<FormikValues>({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      saveMappingInfo?.(selected.map(item => ({
        ...item,
        formGroupId: values.formGroup || null,
        depreciationScheduleId: values.depreciationSchedule || null,
        indexTableId: values.indexTable || null,
        taxable: values.taxable,
        reportable: values.reportable,
        taxYear,
      })));
    },
  });
  useEffect(() => {
    if (!selected.length) {
      formik.setValues(initialValues);
    }
  }, [selected]);

  const isFormChanged = useMemo(() => !_.isEqual(
    initialValues,
    formik.values
  ), [formik.values, initialValues]);

  const getData = useCallback((query?: Query) => {
    if (query) {
      setTableQuery(query)
    }
  }, []);

  useEffect(() => {
    setSelected([]); // clear selected if data change
  }, [formMappingsList]);

  function searchData(
    datas: IBackendFormMapping[],
    search: string | undefined,
    filter: {
      [keyOrProp: string]: unknown;
    } | undefined
  ) {
    const results = _.filter(datas, filter) as IBackendFormMapping[];
    if (search) {
      const escaped = _.escapeRegExp(search);
      return _.filter(
        results,
        (item) => {
          const selectedValue = _.find(commonAssetClassOptions, { value: item.commonClassId });
          return new RegExp(escaped, 'i').test(`${selectedValue?.name ?? item.commonClassId}`)
        }
      );
    } else {
      return results;
    }
  }

  const filterdItems = useMemo(() => {
    return searchData(
      _.orderBy(
        formMappingsList,
        _.last(_.keys(tableQuery?.order)),
        _.last(_.values(tableQuery?.order)),
      ),
      searchStandardAssetClass,
      tableQuery?.filter
    );
  }, [
    formMappingsList,
    tableQuery?.filter,
    tableQuery?.order,
    searchStandardAssetClass
  ]);

  const items = useMemo(() => {
    return _.nth(
      _.chunk(
        filterdItems,
        tableQuery?.pagination?.pageSize,
      ),
      (tableQuery?.pagination?.page as number) - 1,
    ) as IBackendFormMapping[];
  }, [
    filterdItems,
    tableQuery?.pagination
  ]);

  const saveChanges = (newValue: IBackendFormMapping) => {
    updateUnsavedFormMappingsList?.([{
      ...newValue,
      taxYear
    }]);
  };

  useEffect(() => {
    if (formId) {
      setFetchingFormGroup(true);
      getFormGroups({
        formId,
        page: 0
      })
        .then((res) => {
          setFormGroupOptions(res.data.items.map(item => ({
            value: item.id,
            label: item.name,
            name: item.name
          })));
          setFetchingFormGroup(false);
        })
        .catch(() => {
          setFetchingFormGroup(false);
        });
    } else {
      setFormGroupOptions([]);
    }
  }, [formId]);

  useEffect(() => {
    if (depreciationScheduleCollectionId) {
      setFetchingDepreciationSchedule(true);
      getDepreciationSchedules({
        depreciationScheduleCollectionId,
        page: 0
      })
        .then((res) => {
          setDepreciationScheduleOptions(res.data.items.map(item => ({
            value: item.id,
            label: item.name,
            name: item.name
          })));
          setFetchingDepreciationSchedule(false);
        })
        .catch(() => {
          setFetchingDepreciationSchedule(false);
        });
    } else {
      setDepreciationScheduleOptions([]);
    }
  }, [depreciationScheduleCollectionId]);

  useEffect(() => {
    if (indexTableCollectionId) {
      setFetchingIndexTable(true);
      getIndexTables({
        indexTableCollectionId,
        page: 0
      })
        .then((res) => {
          setIndexTableOptions(res.data.items.map(item => ({
            value: item.id,
            label: item.name,
            name: item.name
          })));
          setFetchingIndexTable(false);
        })
        .catch(() => {
          setFetchingIndexTable(false);
        });
    } else {
      setIndexTableOptions([]);
    }
  }, [indexTableCollectionId]);

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'FormManagementSearch d-flex flex-column'
      )
    }>

      {selected.length > 0 && (
        <div className={style.blockSelected}>
          <Divider className={style.divider1} />
          <div
            className='d-flex align-items-end full-width gap-16 justify-content-between flex-wrap'
          >
            <div className='d-flex align-items-end gap-16 flex-wrap'>
              <button
                className={style.textSelectedContainer}
                onClick={() => {
                  setSelected([]);
                }}
              >
                <span className={style.textSelected}>Selected Assets Class ({selected.length})</span>
                <span className={style.textUnselected}>Unselected Assets Class ({selected.length})</span>
              </button>

              <FieldSelect
                labelText='Form Group'
                options={formGroupOptions}
                selectId={formik.values.formGroup}
                onSelect={(value) => {
                  formik.setFieldValue(
                    'formGroup',
                    value?.value,
                  )
                }}
                classnames={style['width-130']}
              />

              <FieldSelect
                labelText='Depreciation Schedule'
                options={depreciationScheduleOptions}
                selectId={formik.values.depreciationSchedule}
                onSelect={(value) => {
                  formik.setFieldValue(
                    'depreciationSchedule',
                    value?.value,
                  )
                }}
                classnames={style['width-130']}
              />

              <FieldSelect
                labelText='Index Table'
                options={indexTableOptions}
                selectId={formik.values.indexTable}
                onSelect={(value) => {
                  formik.setFieldValue(
                    'indexTable',
                    value?.value,
                  )
                }}
                classnames={style['width-130']}
              />

              <FieldToggleCheckbox
                label='Taxable'
                value={formik.values.taxable}
                onChange={(newVal) => {
                  formik.setFieldValue(
                    'taxable',
                    newVal,
                  )
                }}
                classnames={style.FieldToggleCheckbox}
              />

              <FieldToggleCheckbox
                label='Reportable'
                value={formik.values.reportable}
                onChange={(newVal) => {
                  formik.setFieldValue(
                    'reportable',
                    newVal,
                  )
                }}
                classnames={style.FieldToggleCheckbox}
              />
            </div>

            <div className='d-flex gap-12'>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Save'
              >
                <button
                  className='primary'
                  disabled={isSaving || !isFormChanged}
                  onClick={() => {
                    formik.submitForm();
                  }}
                >
                  Save
                  {isSaving ? (
                    <LoaderIndicator
                      className='button-loading'
                      loading={true}
                    />
                  ) : null}
                </button>
              </TooltipWrapper>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Cancel'
              >
                <button
                  className='secondary'
                  onClick={() => {
                    setSelected([]);
                  }}
                >
                  Cancel
                </button>
              </TooltipWrapper>
            </div>
          </div>
        </div>
      )}

      <Table
        id='AssessorManagementDetailsEditTable'
        rows={items}
        onQueryChanged={getData}
        paginate
        sortable
        selectable={isInEditMode ? { selected, onChange: setSelected } : undefined}
        rowId='commonClassId'
        totalRows={filterdItems?.length}
        loading={false}
        showTopBar={false}
        forceVisiblePanel={visibleFilterPanel}
        closeVisiblePanel={() => setVisibleFilterPanel?.(null)}
      >
        <Column
          label='Standard Asset Class'
          prop='commonClassId'
          filterable={{
            type: InputType.Select,
            options: commonAssetClassOptions,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            multi: false,
            searchable: true,
          }}
          accessor={(rowInfo) => {
            return commonAssetClassMapping[rowInfo.commonClassId] || rowInfo.commonClassId || '';
          }}
        ></Column>
        <Column
          label='Form Group'
          prop='formGroupId'
          editable={isInEditMode}
          filterable={{
            type: InputType.Select,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            options: formGroupOptions,
            multi: false,
            searchable: true,
          }}
          editor={{
            type: InputType.Select,
            options: formGroupOptions,
            nullOption: false,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            disabled: isSaving,
          }}
          onChange={(newValue, row) => {
            saveChanges({
              ...row,
              formGroupId: newValue,
            })
          }}
        >
          {
            (value: string) => (
              <span>{formGroupMapping[value]}</span>
            )
          }</Column>
        <Column
          label='Depreciation Schedule'
          prop='depreciationScheduleId'
          editable={isInEditMode}
          filterable={{
            type: InputType.Select,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            options: depreciationScheduleOptions,
            multi: false,
            searchable: true,
          }}
          editor={{
            type: InputType.Select,
            options: depreciationScheduleOptions,
            nullOption: false,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            disabled: isSaving,
          }}
          onChange={(newValue, row) => {
            saveChanges({
              ...row,
              depreciationScheduleId: newValue,
            })
          }}
        >
          {
            (value: string) => (
              <span>{depreciationScheduleMapping[value]}</span>
            )
          }
        </Column>
        <Column
          label='Index Table'
          prop='indexTableId'
          editable={isInEditMode}
          filterable={{
            type: InputType.Select,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            options: indexTableOptions,
            multi: false,
            searchable: true,
          }}
          editor={{
            type: InputType.Select,
            options: indexTableOptions,
            nullOption: false,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            disabled: isSaving,
          }}
          onChange={(newValue, row) => {
            saveChanges({
              ...row,
              indexTableId: newValue,
            })
          }}
        >
          {
            (value: string) => (
              <span>{indexTableMapping[value]}</span>
            )
          }
        </Column>
        <Column
          label="Taxable"
          prop='taxable'
          filterable
          editable={isInEditMode}
          accessor={(data: IBackendFormMapping) => {
            return data.taxable ?? false;
          }}
          editor={{
            type: InputType.Toggle,
          }}
          onChange={(newValue, row) => {
            saveChanges({
              ...row,
              taxable: newValue,
            })
          }}
        />
        <Column
          label="Reportable"
          prop='reportable'
          filterable
          editable={isInEditMode}
          accessor={(data: IBackendFormMapping) => {
            return data.reportable ?? false;
          }}
          editor={{
            type: InputType.Toggle,
          }}
          onChange={(newValue, row) => {
            saveChanges({
              ...row,
              reportable: newValue,
            })
          }}
        />
      </Table>

      <Loader isOpen={
        fetchingFormGroup ||
        fetchingDepreciationSchedule ||
        fetchingIndexTable
      } />
    </div>
  );
}

export default AssessorManagementDetailsTable;