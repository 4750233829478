import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import style from './DepreciationFactorManagementTable.module.scss';
import InitTable, { Query } from '../Table/Table';
import Loader from '../Loader/Loader';
import { getDepreciationSchedule, getErrorMessage } from '../../services';
import { DepreciationScheduleFactor, IToastMessage } from '../../interface';
import _ from 'lodash';

const { Table, Column } = InitTable<DepreciationScheduleFactor>();

interface Props {
  className?: string;
  depreciationScheduleId?: number | null;
}

function DepreciationFactorManagementTable({
  className,
  depreciationScheduleId,
}: Props) {
  const [fetchingFactors, setFetchingFactors] = useState(false);
  const [depreciationFactors, setDepreciationFactors] = useState<DepreciationScheduleFactor[]>([]);
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [tableQuery, setTableQuery] = useState<Query>({});

  const showErrorMessage = (message: string) => {
    window.scrollTo(0, 0);
    setToastMessage({
      message,
      visible: true,
      type: 'error',
    });
  }
  
  useEffect(() => {
    if (depreciationScheduleId) {
      setFetchingFactors(true);
      getDepreciationSchedule(depreciationScheduleId)
        .then((res) => {
          if (res.data) {
            setDepreciationFactors(res.data.factors);
            setFetchingFactors(false);
          }
        })
        .catch((e) => {
          showErrorMessage(getErrorMessage(e));
          setFetchingFactors(false);
        })
    }
  }, [depreciationScheduleId]);

  const factorsPage = useMemo(() => {
    const orderedFactors = _.orderBy(
      depreciationFactors,
      _.last(_.keys(tableQuery?.order)),
      _.last(_.values(tableQuery?.order)),
    )
    return _.nth(
      _.chunk(
        orderedFactors,
        tableQuery?.pagination?.pageSize,
      ),
      (tableQuery?.pagination?.page as number) - 1,
    ) as DepreciationScheduleFactor[];
  }, [
    depreciationFactors,
    tableQuery?.pagination
  ]);

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'DepreciationFactorManagement d-flex flex-column'
      )
    }>
      <Table
        id='AssessorManagementDetailsEditTable'
        rows={factorsPage}
        paginate
        totalRows={depreciationFactors?.length}
        loading={false}
        onQueryChanged={query => setTableQuery(query)}
      >
        <Column
          label='Age'
          prop='age'
        />

        <Column
          label='Value'
          prop='value'
        />
      </Table>

      <Loader isOpen={
        fetchingFactors
      } />
    </div>
  );
}

export default DepreciationFactorManagementTable;