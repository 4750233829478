import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import style from './Header.module.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import { EUserRoles, TOOLTIP_SECTIONS } from '../../enums';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { MaintenanceNotificationResponse, SystemTaxYear, UserModal } from '../../interface';
import useSelectedSystemTaxYear from '../../hook/useSelectedSystemTaxYear';
import { getSystemTaxYears } from '../../services';
import { Dispatch } from 'redux';
import { setSelectedTaxYear } from '../../redux/features/selected-tax-year-slice';
import { useAppDispatch } from '../../redux/store';
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import DOMPurify from 'dompurify';
import { SBanner } from '@avalara/skylab-sdk/react';

interface Props {
  profile: UserModal | null;
  maintenanceNotifications: MaintenanceNotificationResponse;
  setMaintenanceNotifications: (maintenanceNotifications: MaintenanceNotificationResponse) => any
}

function Header(props: Props) {
  const { profile, setMaintenanceNotifications } = props;
  let maintenanceNotifications = props.maintenanceNotifications;
  const userRoles = profile?.roles;
  const isOnlySystemAdmin =
    userRoles &&
    userRoles.length === 1 &&
    (
      userRoles[0] === EUserRoles.SystemAdministrator
      || userRoles[0] === EUserRoles.SupportAdministrator
    );
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const selectedSystemTaxYear = useSelectedSystemTaxYear();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const quiteImpersonate = () => {
    sessionStorage.removeItem('user-account-id');
    window.location.href = '/admin/purchase-returns';
  };

  // On component initialization
  useEffect(() => {
    getSystemTaxYears()
      .then(result => {
        if (result.data.length === 0) return;

        // Set options for tax year dropdown
        const activeTaxYears = result.data
          .filter((taxYear: SystemTaxYear) => taxYear.isActive)
          .sort((taxYear1: SystemTaxYear, taxYear2: SystemTaxYear) => {
            if (taxYear1.taxYear < taxYear2.taxYear) {
              return 1;
            }
            if (taxYear1.taxYear > taxYear2.taxYear) {
              return -1;
            }
            return 0;
          });

        // Set selected option for tax year dropdown
        if (!selectedSystemTaxYear.taxYear) {
          const currentTaxYears = activeTaxYears.filter((taxYear: SystemTaxYear) => taxYear.isCurrent);
          if (currentTaxYears.length === 0) {
            currentTaxYears.push(activeTaxYears[0]);
          }

          dispatch(setSelectedTaxYear({ taxYear: currentTaxYears[0].taxYear }));
        }
      });
    return () => {
      sessionStorage.removeItem('user-account-id');
    };
  }, []);

  function shouldShowTaxYear() {
    const routesToHide = ['reports', 'users', 'plans', 'taxyearselection', 'admin'];
    let shouldShow = true;
    routesToHide.forEach(routeName => {
      if (location.pathname.match(routeName)) {
        shouldShow = false;
      }
    })
    return shouldShow;
  }

  return (
    <header>
      {/* Logo */}
      <div className={classNames(style['header-bar'])}>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Header}
          tooltipKey='Account'
        >
          <p>
            <span className={style['badge']}>
              {_.upperCase(profile?.userAccount?.businessName.charAt(0))}
            </span>
            {profile?.userAccount?.businessName}
          </p>
        </TooltipWrapper>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Header}
          tooltipKey='Exit'
        >
          <button className='secondary' onClick={quiteImpersonate}>
            Exit
          </button>
        </TooltipWrapper>
      </div>
      <div className={style['header']}>
        <Link to='/dashboard' className={style['logo-container']}>
          <img src="/assets/images/Avalara-PropTaxPro-Stacked-RGB.png" className={style['nav-logo']} />
        </Link>
        {shouldShowTaxYear() && (
          <div className={style['taxyear-container']}>
            <span className={style['color']}>Tax Year {selectedSystemTaxYear.taxYear}</span>
          </div>)
        }
        <div className={classNames(style['blocks'])}>
          {/* Help link */}
          {!isOnlySystemAdmin && (
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Header}
              tooltipKey='Help'
            >
              <Link to={'/help'} className={classNames('link', style['help'])}>
                Help
              </Link>
            </TooltipWrapper>
          )}
          {/* Account block */}
          <div className={style['block']}>
            <OutsideClickHandler
              onOutsideClick={() => {
                setMenuVisible(false);
              }}
            >
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.Header}
                tooltipKey='Account'
              >
                {sessionStorage.getItem('user-account-id') ? (
                  <span
                    role='link'
                    onClick={() => setMenuVisible(true)}
                    className={classNames('link', style['account'])}
                  >
                    <span className={style['badge']}>
                      {_.upperCase(profile?.userAccount?.firstName.charAt(0))}
                      {_.upperCase(profile?.userAccount?.lastName.charAt(0))}
                    </span>
                    {_.get(profile?.userAccount, 'fullName')}
                  </span>
                ) : (
                  <span
                    role='link'
                    onClick={() => setMenuVisible(true)}
                    className={classNames('link', style['account'])}
                  >
                    <span className={style['badge']}>
                      {_.upperCase(profile?.firstName.charAt(0))}
                      {_.upperCase(profile?.lastName.charAt(0))}
                    </span>
                    Account
                  </span>
                )}
              </TooltipWrapper>
              {menuVisible && (
                <div className={style['options']}>
                  <ul onClick={() => setMenuVisible(false)}>
                    {_.includes(userRoles, EUserRoles.AccountAdministrator) && (
                      <li>
                        <TooltipWrapper
                          tooltipSection={TOOLTIP_SECTIONS.Header}
                          tooltipKey='Account Settings'
                        >
                          <Link to='/users'>Account Settings</Link>
                        </TooltipWrapper>
                      </li>
                    )}
                    <li>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.Header}
                        tooltipKey='Logout'
                      >
                        <Link to='/logout'>Logout</Link>
                      </TooltipWrapper>
                    </li>
                  </ul>
                </div>
              )}
            </OutsideClickHandler>
          </div>
        </div>
      </div>
      <div>
        {maintenanceNotifications?.items?.length > 0 && maintenanceNotifications?.items?.map((maintenanceNotification, index) => {
          return index === 0 && <SBanner
            id={maintenanceNotification.id?.toString()}
            key={`application-notification-${maintenanceNotification.id?.toString()}`}
            aria-label="Info banner"
            status="info"
            onS-dismiss={(e: CustomEvent) => {
              maintenanceNotifications = {
                ...maintenanceNotifications,
                items: maintenanceNotifications?.items?.filter(notification => notification.id != e.detail.id)
              }
              dispatch(setMaintenanceNotifications(maintenanceNotifications));
            }}
          >
            <div
            className={style['content']}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(maintenanceNotification.content || ''),
            }}
            ></div>
          </SBanner>
        })}
      </div>
    </header>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setMaintenanceNotifications: (maintenanceNotifications: MaintenanceNotificationResponse) =>
    dispatch(actions.setMaintenanceNotifications(maintenanceNotifications)),
});

export default connect(null, mapDispatchToProps)(Header);